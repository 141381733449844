<template>
  <v-row>
    <v-col cols="12" style="padding:0px !important;">
      
      <v-container fluid>
        <v-layout>
              <v-col cols="12" align="center" >
                <v-img src="img/feedbackForm.png" alt="Help Center" width="550" style="border-radius:10px;" >
                </v-img>
                <h1>qikPharma Feedback Form</h1>

                <v-row class="mt-3">
                    <v-col cols="12"  md="10" offset-md="1">
                        <v-layout>
                            <v-col cols="12" md="6" offset-md="3" align="left" >
                                <v-layout>
                                    <p align="center">
                                        Welcome to qikPharma - Your trusted one-stop shop, for all things pharmaceutical. 
                                        Please could you help us test our qikPharma website and drop a quick review of what you think.
                                    </p>
                                </v-layout>
                                <v-layout color="#BD2121" v-if="successMessage">
                                    <v-col cols="12">
                                    <h5 class="text-primary" align="center">
                                        Feedback submitted successfully.
                                    </h5>
                                    </v-col>
                                </v-layout>
                                <form @submit.prevent="sendEmail">
                                    <v-layout>
                                        <v-text-field
                                        v-model="name"
                                        name="name"
                                        outlined
                                        dense
                                        label="Name: (Optional)"
                                        ></v-text-field>
                                    </v-layout>
                                    <v-layout>
                                        <v-text-field
                                        v-model="email"
                                        name="email"
                                        outlined
                                        dense
                                        label="Email: (Optional)"
                                        ></v-text-field>
                                    </v-layout>
                                    <v-layout>
                                        <v-select
                                        v-model="userType"
                                        name="userType"
                                        :items="userTypes"
                                        label="How would you describe yourself"
                                        outlined
                                        dense
                                        item-text="name"
                                        ></v-select>
                                    </v-layout>
                                    <v-layout>
                                        <v-textarea
                                        v-model="feedback"
                                        name="feedback"
                                        outlined
                                        dense
                                        label="Please leave your comments here, thank you"
                                        ></v-textarea>
                                    </v-layout>
                                    <v-layout>
                                        <v-progress-linear
                                        color="#7CBF46"
                                        v-show="loader2"
                                        :indeterminate="true"
                                        ></v-progress-linear>
                                    </v-layout>
                                    <v-row>
                                        <v-col cols="12" align="center">
                                        <v-btn type="submit" class="buttonStyle">
                                            Submit
                                        </v-btn>
                                        </v-col>
                                    </v-row>
                                </form>
                            </v-col>
                        </v-layout>
                    </v-col>
                </v-row>
              </v-col>
        </v-layout>


      </v-container>

      <v-divider></v-divider>

    </v-col>
  </v-row>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: "About",

  data: () => ({
      loader2:false,
      successMessage:false,
      userTypes: [
        "Manufacturer",
        "Wholesaler",
        "Retailer",
        "Doctor",
        "Pharmacist",
        "Nurse",
        "Consumer"
      ],
      name:"",
      email:"",
      userType:"",
      feedback:"",

      
  }),
  methods: {
    sendEmail(e) {
      this.loader2 = true;
      this.successMessage = false;
      try {
        emailjs.sendForm('service_e2obs9d', 'template_q2du1ak',e.target,'BpesDxyJlWCQRhwvC',
        {
          name: this.name,
          email: this.email,
          userType: this.userType,
          feedback: this.feedback
        })
        this.loader2 = false;
        this.successMessage = true;

      } catch(error) {
          console.log({error})
          this.loader2 = false;
      }
      // Reset form field
      this.name = '';
      this.email = '';
      this.userType = '';
      this.feedback ='';
    }
  },
};
</script>

<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
}

.box1 {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff;
}

.box2 {
  background: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  color: #171a17;
}

.barcodeTitle {
  color: #000000;
  font-size: 16px;
  text-align: center;
}

.barcodeText {
  color: #c2c2c2;
  font-size: 12px;
  margin-top: 15px;
  text-align: center;
}

.centerImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
}

p {
  line-height: 1.5;
}

</style>
